
<div
  class="compositions__monaco-editor"
  :class="[
    hideDetails && 'hide-details',
    disabledInteraction && 'disabled-interaction',
  ]"
>
  <div class="rounded-md overflow-hidden relative">
    <div
      id="container"
      ref="containerRef"
      :style="{ width: newWidth, height: newHeight }"
    ></div>
  </div>

  <VInput
    class="mt-1"
    :value="value"
    :rules="rules"
    @update:error="(v) => (error = v)"
  />

  <div v-if="enableZoom" class="flex items-center slider-view">
    <v-icon color="primary" @click="updateFontSize(-1)">mdi-minus</v-icon>
    <v-slider
      :value="currentFontSize"
      :max="48"
      :min="8"
      :step="1"
      @input="(value) => updateFontSize(value, true)"
    ></v-slider>
    <v-icon color="primary" @click="updateFontSize(1)">mdi-plus</v-icon>
  </div>
</div>
