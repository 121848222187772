import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "/Setup",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      /** addon 서비스 리스트 */
      {
        path: "addonservice",
        name: "AddonServiceListPage",
        component: () =>
          import("../pages/AddonServiceV2/AddonServiceListPageV2.vue"),
      },

      /** addon 서비스 생성 */
      {
        path: "addonService/create",
        name: "AddonServiceCreate",
        component: () =>
          import("../pages/AddonServiceV2/AddonServiceCreatePageV2.vue"),
        meta: {
          type: "create",
        },
      },
      /** addon 서비스 상세 */
      {
        path: "addonService/:addonServiceId",
        name: "AddonServiceDetailPage",
        component: () =>
          import("../pages/AddonServiceV2/AddonServiceViewPageV2.vue"),
      },
      {
        path: "addonService/:addonServiceId/edit",
        name: "AddonServiceEditPage",
        component: () =>
          import("../pages/AddonServiceV2/AddonServiceCreatePageV2.vue"),
      },
    ],
  },
];
