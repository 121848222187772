<template>
  <div
    class="common__page-header bg-white"
    :class="[
      fixed ? 'sticky top-0 z-[5] shadow-md' : 'border-b border-b-gray-300',
    ]"
  >
    <div :class="['h-11 m-4 grid grid-flow-col items-center ']">
      <!-- left -->
      <div class="overflow-visible flex gap-4">
        <slot name="left"></slot>

        <!-- breadcrumbs -->
        <Breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />
        <slot name="left-next"></slot>
      </div>

      <!-- center -->
      <div class="flex justify-center items-center">
        <slot name="center"></slot>
      </div>

      <!-- right -->
      <div class="flex justify-end items-center">
        <a
          v-if="useBackButton"
          :class="$slots.right && 'mr-2'"
          @click.prevent="$router.back()"
        >
          <Button color="accent" text outlined>뒤로</Button>
        </a>
        <slot name="right"></slot>
      </div>
    </div>
    <div class="w-full pb-4">
      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import Button from "@/components/atoms/Button/Button.vue";
import Breadcrumbs from "@/components/compositions/Breadcrumbs/Breadcrumbs.vue";

export default {
  name: "PageHeaderV2",
  props: {
    /** breadcrumbs 표시 */
    breadcrumbs: {
      type: Array,
      default: undefined,
    },
    /** true일 경우 고정됨 */
    fixed: {
      type: Boolean,
      default: false,
    },
    /** true일 경우 뒤로가기 버튼 활성화 */
    useBackButton: {
      type: Boolean,
      default: false,
    },
  },
  components: { Breadcrumbs, Button },
};
</script>

<style lang="scss" scoped>
.common__page-header {
  margin: 15px !important;
  top: 0 !important;
  border-radius: 8px;
  padding: 0 16px;
  .page-header__breadcrumb {
    @apply text-gray-400;
    li > a {
      @apply text-gray-500;
    }
  }
}
</style>
