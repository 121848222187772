
<v-dialog v-model="show" persistent :max-width="maxWidth">
  <v-card class="p-3 pl-5">
    <template v-if="!!title">
      <v-card-title>
        <span class="dialog-title">{{ title }}</span>
        <v-spacer />
        <v-btn icon color="grey" @click="closePopup">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
    </template>
    <v-container class="p-0">
      <slot>
        <component
          :is="content"
          v-bind="contentProps"
          :closePopup="closePopup"
        />
      </slot>
    </v-container>
    <v-card-actions v-if="actions" class="flex justify-end">
      <v-btn
        v-for="(action, index) in actions"
        :key="index"
        class="no-uppercase"
        :color="action.color"
        :fill="action.fill"
        @click="() => action.onClick(this.closePopup)"
      >
        {{ action.text }}
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
