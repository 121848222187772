
<div
  class="common__page-header bg-white"
  :class="[
    fixed ? 'sticky top-0 z-[5] shadow-md' : 'border-b border-b-gray-300',
  ]"
>
  <div :class="['h-11 m-4 grid grid-flow-col items-center ']">
    <!-- left -->
    <div class="overflow-visible flex gap-4">
      <slot name="left"></slot>

      <!-- breadcrumbs -->
      <Breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />
      <slot name="left-next"></slot>
    </div>

    <!-- center -->
    <div class="flex justify-center items-center">
      <slot name="center"></slot>
    </div>

    <!-- right -->
    <div class="flex justify-end items-center">
      <a
        v-if="useBackButton"
        :class="$slots.right && 'mr-2'"
        @click.prevent="$router.back()"
      >
        <Button color="accent" text outlined>뒤로</Button>
      </a>
      <slot name="right"></slot>
    </div>
  </div>
  <div class="w-full pb-4">
    <slot name="bottom"></slot>
  </div>
</div>
