import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      {
        path: "/Setup/cluster",
        name: "ClusterList",
        component: () => import("../pages/clusterV2/ClusterListPageV2.vue"),
        // component: () =>
        //   import("../pages/main/modernframework/Cluster/ClusterList.vue"),
      },
      {
        path: "/Setup/cluster/add",
        name: "ClusterCreate",
        component: () => import("../pages/cluster/ClusterAddPage.vue"),
      },
      {
        path: "/Setup/cluster/edit/:clusterId",
        name: "ClusterEdit",
        component: () => import("../pages/cluster/ClusterEditPage.vue"),
      },
      {
        path: "/Setup/cluster/:clusterId",
        name: "ClusterDetail",
        component: () => import("../pages/clusterV2/ClusterDetailPageV2.vue"),
      },
    ],
  },
];
